export const formItem = [
	{
		type: 'select',
		label:'账单类型',
		prop:'type',
		option:[{ id:1,name:'手动生成'},{ id:2,name:'自动生成'}],
		placeholder:'请选择账单类型'
	},
]
export const billColumns = [
	{
		title: "操作人",
		dataIndex: "createName"
	},
	{
		title: "身份类型",
		dataIndex: "identity",
		customRender: function (identity) {
			switch (identity) {
			case 1: return '物业'
			case 2: return '业主'
			case 3: return '租户'
			case 4: return '业主亲属'
			case 5: return '租户亲属'
			default:
				break;
			}
		}
	},
	{
		title: "实缴金额",
		dataIndex: "payAmount"
	},
	{
		title: "支付类型",
		dataIndex: "payType",
		customRender:function(payType){
			switch (payType) {
			case 1: return '自动扣费'
			case 2: return '线下支付'
			case 3: return '支付宝支付'
			default:
				break;
			}
		}
	},
];
export const columns = [
	{
		title: "账单名称",
		dataIndex: "name",
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
		width: 220
	},
	{
		title: "账单状态",
		dataIndex: "status",
		customRender:function(status){
			switch (status) {
			case 1:
				return '未缴纳'
			case 2:
				return '部分缴纳'
			case 3:
				return '已缴纳'
			case 4:
				return '已废除'
			default:
				break;
			}
		}
	},
	{
		title: "关联房屋",
		dataIndex: "unitName",
		scopedSlots: { customRender: "unitName" },
	},
	{
		title: "收费标准",
		dataIndex: "chargesName"
	},
	{
		title: "计费周期",
		dataIndex: "billDateStart",
		scopedSlots: { customRender: "billDateStart" },
		width: 400
	},
	{
		title: "应收金额",
		dataIndex: "amountReceivable"
	},
	{
		title: "违约金额",
		dataIndex: "defaultAmount"
	},
	{
		title: "实收金额",
		dataIndex: "amountReceived"
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}