<template>
  <div>
	<div class="cardTitle">账单明细</div>
    <a-tabs v-model="form.status" @change="changeStatus">
      <a-tab-pane :key="undefined" tab="全部"> </a-tab-pane>
      <a-tab-pane :key="1" tab="未缴纳"> </a-tab-pane>
      <a-tab-pane :key="3" tab="已缴纳"> </a-tab-pane>
      <a-tab-pane :key="4" tab="已废除"> </a-tab-pane>
    </a-tabs>
    <div class="search-form">
        <a-form-model style="margin-left: 32px" layout='inline'>
            <a-form-model-item label="流水号">
                <a-select style="width: 150px" v-model="form.type" placeholder="请选择">
                    <a-select-option :value="1">
                        手动生成
                    </a-select-option>
                    <a-select-option :value="2">
                        自动生成
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item style="margin-left: 32px" label="关联房屋" >
                <a-cascader v-model="casVal" @change="chooseHouse" :field-names="{label: 'name', value: 'id', children: 'childList' }" :options="houseInfo" style="width: 200px" placeholder="请选择">
                </a-cascader>
            </a-form-model-item>
            <a-form-model-item style="margin-left: 32px" label="楼栋" >
                <a-select @change="changeBuilding" v-model="form.buildingId" style="width: 100px">
                    <a-select-option v-for="(item) in builidingList" :key="item.id" :value="item.id">{{item.name+'栋'}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item style="margin-left: 32px" label="单元" >
                <a-select :disabled="form.buildingId == undefined" v-model="form.unitId" style="width: 100px">
                    <a-select-option v-for="item in unitList" :key="item.id" :value="item.id">{{item.name+'单元'}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item style="margin-left: 32px" label="楼层" >
                <a-input-number v-model="form.floorNum" style="width: 100px"></a-input-number>
            </a-form-model-item>
            <a-form-model-item style="margin-left: 32px" label="收费标准">
                <a-select v-model="chargesVal" style="width: 150px" @change="chargeChoose">
                    <a-select-option v-for="(item) in chargeInfo" :key="item.id" :value="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
        <div class="btn-box">
            <a-button type="primary" @click="search"> 查询 </a-button>
            <a-button style="margin-left: 10px" @click="resetForm"> 重置 </a-button>
        </div>
    </div>
    <!-- <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm> -->
    <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :scroll="{x: 1900}" @change="handlerPage">
        <template slot="unitName" slot-scope="text, record">
            {{record.buildingName}}/{{record.unitName}}/{{record.estateName}}
        </template>
        <template slot="billDateStart" slot-scope="text, record">
            {{record.billDateStart}} 至 {{record.billDateEnd}}
        </template>
        <template slot="action" slot-scope="text, record">
            <a v-if="record.status != 4" @click="billPay(record)">支付</a>
            <a v-if="record.status != 4" style="margin-left: 8px;color: red" @click="billOff(record)">废除</a>
            <a v-if="record.status == 4" @click="billRecover(record)">恢复</a>
        </template>
    </a-table>
    <a-drawer title="账单详情"
      :width="720"
      :visible="billShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="billClose">
        <div class="drawer-content">
            <div style="padding: 16px">
                <span class="billTitle">{{12786127846}}</span><a-tag style="margin-left: 24px">{{offDetail.status==1?'未缴纳':2?'部分缴纳':'已缴纳'}}</a-tag>
                <span style="float: right">剩余未缴</span>
            </div>
            <div style="padding: 16px">
                <span>{{offDetail.buildingName}}/{{offDetail.unitName}}/{{offDetail.estateName}}</span>
                <span style="float: right;color: #D53131">¥{{offDetail.amountReceivable}}</span>
            </div>
            <a-divider></a-divider>
            <div style="padding: 16px">
                <a-descriptions layout="vertical">
                    <a-descriptions-item label="应收金额">
                        {{(offDetail.amountReceivable*1).toFixed(2)}}
                    </a-descriptions-item>
                    <a-descriptions-item label="实收金额">
                        {{(offDetail.amountReceived*1).toFixed(2)}}
                    </a-descriptions-item>
                    <a-descriptions-item label="计费周期">
                        {{offDetail.billDateStart}} 至 {{offDetail.billDateEnd}}
                    </a-descriptions-item>
                    <a-descriptions-item label="剩余应收">
                        {{(offDetail.amountReceivable*1 - offDetail.amountReceived*1).toFixed(2)}}
                    </a-descriptions-item>
                    <a-descriptions-item label="违约金">
                        {{(offDetail.defaultAmount * 1).toFixed(2)}}
                    </a-descriptions-item>
                    <a-descriptions-item label="创建时间">
                        {{offDetail.createDate}}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <div class="tableTitle">缴费记录</div>
            <a-divider></a-divider>
            <a-table :columns="billColumns" :data-source="billDetail" :pagination="false">  
            </a-table>
        </div>
        <div class="drawer-footer">
            <a-button :style="{ marginRight: '8px' }" @click="billClose">
            关闭
            </a-button>
            <a-button type="primary" @click="billPayment"> 支付 </a-button>
        </div>
    </a-drawer>
    <a-modal title="账单废除确认" :visible="offShow" @ok="offComfirm" @cancel="offCancel">
        <div>房屋号：{{offDetail.buildingName}}/{{offDetail.unitName}}/{{offDetail.estateName}}</div>
        <div>收费标准：{{offDetail.chargesName}}</div>
        <div>剩余应收：<span style="color: #D53131">{{offDetail.amountReceivable}}</span></div>
        <div>创建时间：{{offDetail.billDateStart}}</div>
        <h4 style="margin-top: 32px">请确认是否废除该账单？</h4>
    </a-modal>
    <a-modal title="账单结清" :visible="paymentShow" @ok="paymentConfirm" @cancel="paymentCancel">
        <div style="padding: 24px 0px 24px 0px">总计金额：<span style="color: #D53131">{{(offDetail.amountReceivable*1).toFixed(2)}}</span></div>
        <div>房屋号：{{offDetail.buildingName}}/{{offDetail.unitName}}/{{offDetail.estateName}}</div>
        <div>剩余应收：<span>{{(offDetail.amountReceivable*1 - offDetail.amountReceived*1).toFixed(2)}}</span></div>
        <div>违约金：{{(offDetail.defaultAmount * 1).toFixed(2)}}</div>
        <div>创建时间：{{offDetail.billDateStart}}</div>
        <h4 style="margin-top: 32px">请确认是否结清该账单？</h4>
    </a-modal>
  </div>
</template>

<script>
import { formItem, columns, pagination, billColumns } from "./depend/config"
import { getBillList, abolition, recover, findBillOrderList, offlinePayments } from "@/api/payment/billManagement"
import { findEstateCascade } from "@/api/basic/estate"
import { chargesList } from "@/api/payment/chargeStandardManage"
import { allBuilding, findByBuildingId } from "@/api/basic/estate"
export default {
    name: 'payInfo',
    data() {
        return {
            form: {
                type: undefined,
                estateId: undefined,
                chargesIds: [],
                buildingId: undefined,
                unitId: undefined,
                floorNum: undefined,
                status: undefined,
            },
            casVal: [],
            chargesVal: undefined,
            builidingList: [],
            unitList: [],
            /////
            tableData:[],
            formItem,
            columns,
            pagination,
            billShow: false,
            offShow: false,
            billColumns: billColumns,
            //详情
            currentId: undefined,
            billDetail: [],
            offDetail: {},
            //支付
            paymentShow: false,
            //房屋信息
            houseInfo: [],
            //收费标准信息
            chargeInfo: []
        }
    },
    mounted() {
        this.getApi()
        this.getData()
    },
    methods: {
        getApi() {
            findEstateCascade().then(res => {
                let data = res.data;
                this.houseInfo = data;
            })
            chargesList({pageNum:1,size:1000}).then(res => {
                let data = res.data;
                this.chargeInfo = data.rows
            })
            allBuilding().then(res => {
                let data = res.data;
                this.builidingList = data;
            })
        },
        getData() {
            let obj = Object.assign({pageNum: this.pagination.current, size: this.pagination.pageSize},this.form)
            getBillList(obj).then(res => {
                let data = res.data;
                this.tableData = data.rows;
                this.pagination.total = data.total
            })
        },
        search() {
            this.getData()
        },
        resetForm() {
            this.form = {
                type: undefined,
                estateId: undefined,
                chargesIds: undefined
            };
            this.casVal = [];
            this.chargesVal = undefined;
            this.getData();
        },
        chooseHouse(val) {
            let v = val;
            let index = val.length
            this.form.estateId = v[index-1]
        },
        chargeChoose(val) {
            this.form.chargesIds = [val]
        },
        handlerPage(val) {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        //切换状态
        changeStatus() {
            this.getData();
        },
        //根据楼栋查单元
        changeBuilding(val) {
            findByBuildingId({buildingId: val}).then(res => {
                let data = res.data;
                this.unitList = data;
            })
        },
        //支付
        billPay(record) {
            this.currentId = record.id
            this.offDetail = record;
            let obj = {billId: record.id}
            findBillOrderList(obj).then(res => {
                let data = res.data
                this.billDetail = data;
            })
            this.billShow = true
        },
        //支付结清
        billPayment() {
            this.paymentShow = true;
        },
        paymentConfirm() {
            let obj = {billId: this.currentId, payAmount: (this.offDetail.amountReceivable - this.offDetail.amountReceived)*1.00}
            offlinePayments(obj).then(res => {
                if (res.code === 200) {
					this.$message.success(res.msg);
                    this.paymentShow = false;
                    this.billShow = false;
					this.getData();
				} else {
					this.$message.error(res.msg);
				}
            })
        },
        paymentCancel() {
            this.paymentShow = false;
        },
        //废除
        billOff(record) {
            this.currentId = record.id
            this.offDetail = record;
            this.offShow = true;
        },
        offComfirm() {
            abolition({billId: this.currentId}).then(res => {
                if (res.code === 200) {
					this.$message.success(res.msg);
                    this.offShow = false;
					this.getData();
				} else {
					this.$message.error(res.msg);
				}
            })
        },
        //恢复
        billRecover(record) {
            let obj = {billId : record.id}
            this.$confirm({
					title: "是否恢复?",
					icon: "redo",
					onOk: () => {
						recover(obj).then(
							(res) => {
								if (res.code === 200) {
									this.$message.success(res.msg);
									this.getData();
								} else {
									this.$message.error(res.msg);
								}
							}
						);
					},
				});
        },
        //抽屉
        billClose() {
            this.billShow = false
        },
        offCancel() {
            this.offShow = false
        }
    }
}
</script>

<style lang="less">
.search-form {
    text-align: left;
    background: #f9f9f9;
    padding-top: 25px;
    padding-bottom: 1px;
}
.btn-box{
  margin:10px 0 15px 100px;
}
.billTitle {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
}
.tableTitle {
    padding-left: 16px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}
</style>