import httpService from "@/request"

// 后台账单list
export function getBillList(params) {
	return httpService({
		url: `/user/bill/billList`,
		method: 'post',
		data: params,
	})
}

// 账单废除
export function abolition(params) {
	return httpService({
		url: `/user/bill/abolition`,
		method: 'get',
		params: params,
	})
}

// 账单恢复
export function recover(params) {
	return httpService({
		url: `/user/bill/recover`,
		method: 'get',
		params: params,
	})
}

// 账单线下支付
export function offlinePayments(params) {
	return httpService({
		url: `/user/bill/offlinePayments`,
		method: 'post',
		data: params,
	})
}

// 根据账单主键id查询账单订单
export function findBillOrderList(params) {
	return httpService({
		url: `/user/bill/findBillOrderList`,
		method: 'get',
		params: params,
	})
}